const error = {
    createBusiness: 'Der Betrieb konnte nicht erstellt werden.',
    createOrder: 'Die Bestellung konnte nicht erstellt werden.',
    createRequest: 'Die Anfrage konnte nicht durchgeführt werden.',
    empty: "{target} ist leer. | {target} sind leer.",
    invalid: "{target} ist nicht korrekt. | {target} sind nicht korrekt.",
    invalidLogin: 'Logindaten ungültig',
    noAdmin: "Keine Adminberechtigung gefunden.",
    noAuth: "{target} hat keine Berechtigung. | {target} haben keine Berechtigung.",
    noMatch: `{target} stimmt nicht überein`,
    notFound: "{target} wurde nicht gefunden. | wurden nicht gefunden.",
    passwordResetRequest: '@:error.createRequest',
    requestSent: 'Anfrage konnte nicht versendet werden.',
    supportFormSent: 'Supportformular konnte nicht gesendet werden.',
    resetRequest: 'Anfrage konnte nicht versendet werden.'
}

export default error
