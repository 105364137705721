<template>
    <select-base
        v-model="model"
        multiple
        use-input
        use-chips
        input-debounce="0"
        :option-value="optionValue"
        :option-label="optionLabel"
        :label="label"
        :options="options"
        hide-bottom-space
        :readonly="readonly"
        :validation="validation"
        :clearable="clearable"
        @filter="selectFilter"
    >
        <template #no-option>
            <q-item>
                <q-item-section class="text-grey">
                    {{ $t('input.noResult') }}
                </q-item-section>
            </q-item>
        </template>
    </select-base>
</template>

<script setup lang="ts">
import { QItem, QItemSection, QSelectProps } from "quasar";

import { SelectBase } from "@/components";

import { useSelectFilterObject } from "@/composables/useSelectFilter.ts";

import { FunctionType, InputValidation } from "@/types";
import { SectorSuggestion } from "@/types/object/class/db.ts";

const model = defineModel({ required: true })
const props = defineProps<{
    label: string;
    objectKeys: string[];
    optionLabel: FunctionType<string, [any]>;
    optionsSource: any[];
    optionValue: FunctionType<string, [any]>;
    validation?: InputValidation;
} & QSelectProps>()

const { options, reloadSources, selectFilter } = useSelectFilterObject<SectorSuggestion>({
    objectKeys: props.objectKeys,
    optionsSource: props.optionsSource
})

defineExpose({
    reloadSources
})
</script>

<style scoped>

</style>
