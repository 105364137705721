<template>
    <select-filter
        ref="suggestion"
        v-model="model"
        :options-source="suggestionStore.sectorSuggestions"
        :object-keys="['suggestion']"
        :option-label="(opt) => opt?.suggestion"
        :option-value="(opt) => opt?.id"
        :label="required ? `${$t('input.services')}*` : $t('input.services')"
        :readonly="readonly"
        :validation="validation"
        clearable
    />
</template>

<script setup lang="ts">
import { onMounted, useTemplateRef } from "vue";

import SelectFilter from "@/components/input/SelectFilter.vue";

import { useSuggestionStore } from "@/stores/suggestion.ts";

import { InputValidation } from "@/types";

const model = defineModel({ required: true })
const select = useTemplateRef('suggestion')
const suggestionStore = useSuggestionStore()

defineProps<{ readonly?: boolean; validation?: InputValidation; required?: boolean }>()

const init = async () => {
    await suggestionStore.getSuggestions()
    select.value?.reloadSources?.(suggestionStore.sectorSuggestions)
}

onMounted(init)
</script>

<style scoped>

</style>
