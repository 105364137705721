<template>
    <component :is="layout">
        <router-view />

        <version-code />
    </component>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

import { VersionCode } from "@/components";

import { useUserStore } from "@/stores/user.ts";

import { useRouteMeta } from "@/composables";
import { route } from "@/router";

const { layout } = useRouteMeta()

onMounted(() => {
    if (!route.value.meta?.noAuth) useUserStore().me()
})
</script>

<style scoped>

</style>
