import { defineStore } from "pinia"

import { request } from "@/functions";

import { PaginationResponse } from "@/types";
import { Suggestion } from "@/types/object/class/db.ts";

interface SuggestionStore {
    sectorMap: Record<string, string>;
    sectorSuggestions: Suggestion[];
}

export const useSuggestionStore = defineStore('suggestion', {
    actions: {
        async getSuggestions() {
            const response = await request<PaginationResponse<Suggestion>>({
                method: 'GET',
                route: '/suggestions',
                query: {
                    limit: 0
                }
            })

            if (!response) return

            this.sectorSuggestions = response.data
            response.data.forEach(sectorSuggestion => {
                if (!sectorSuggestion.id) return
                this.sectorMap[sectorSuggestion.id] = sectorSuggestion.suggestion
            })
        }
    },
    getters: {},
    state: (): SuggestionStore => {
        return {
            sectorMap: {},
            sectorSuggestions: []
        }
    }
})
