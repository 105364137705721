import { useUserStore } from "@/stores/user.ts";

import { convertToISO } from "@/functions/luxon.ts";

import { Address, Business, Order } from "@/types/object/class/db.ts";

import i18n from "@/plugins/vue-i18n";

import type { DeviceInfo, SelectOption } from "@/types";

export const getOptions = <T extends Record<string, unknown>>(originOptions: T[], valueKey: keyof T, textKey: ((obj: T) => number | string | undefined) | keyof T): SelectOption[] => {
    const options: SelectOption[] = []
    for (const item of originOptions) {
        if (item?.[valueKey] === undefined) continue;
        if (textKey instanceof Function) {
            const text = textKey(item)
            if (text === undefined) continue;
            options.push({
                text,
                value: item?.[valueKey]
            })
        } else {
            const text: unknown = item[textKey]
            if (text === undefined) continue;
            if (!(typeof text === 'string' || typeof text === 'number')) continue;
            options.push({
                text,
                value: item[valueKey]
            })
        }
    }
    return options
}

export const compareFormat = (value: unknown): string => {
    if (typeof value === 'boolean') return (+value).toString()
    if (typeof value !== 'string' && typeof value !== 'number') return ''
    return value.toString().trim().toLowerCase()
}

export const translateJoin = (array: unknown[], translationSelector: string, separator: string): string => {
    const translatedArray: string[] = []

    for (const element of array) {
        translatedArray.push(i18n.global.t(translationSelector + element))
    }

    return translatedArray.join(separator)
}

export const parseUserAgent = (userAgent: string) => {
    const uaFields: Partial<DeviceInfo> = {};
    const start = userAgent.indexOf('(') + 1;
    let end = userAgent.indexOf(') AppleWebKit');
    if (userAgent.indexOf(') Gecko') !== -1) {
        end = userAgent.indexOf(') Gecko');
    }
    const fields = userAgent.substring(start, end);

    uaFields.model = fields.split('; ')[0];


    if (userAgent.indexOf('Windows') !== -1) {
        uaFields.osVersion = fields;
    } else {
        const tmpFields = fields.split('; ').pop();
        if (tmpFields) {
            const lastParts = tmpFields
                .replace(' like Mac OS X', '')
                .split(' ');
            uaFields.osVersion = lastParts[lastParts.length - 1].replace(/_/g, '.');
        }
    }

    if (/Win/.test(userAgent)) {
        uaFields.operatingSystem = 'windows';
    } else if (/Mac/i.test(userAgent)) {
        uaFields.operatingSystem = 'mac';
    } else {
        uaFields.operatingSystem = 'unknown';
    }
    //Copied from ionic Device code
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const isSafari = !!window.ApplePaySession;
    //Copied from ionic Device code
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const isChrome = !!window.chrome;
    const isFirefox = /Firefox/.test(userAgent);
    const isEdge = /Edg/.test(userAgent);
    const isFirefoxIOS = /FxiOS/.test(userAgent);
    const isChromeIOS = /CriOS/.test(userAgent);
    const isEdgeIOS = /EdgiOS/.test(userAgent);
    // FF and Edge User Agents both end with "/MAJOR.MINOR"
    if (isSafari ||
        (isChrome && !isEdge) ||
        isFirefoxIOS ||
        isChromeIOS ||
        isEdgeIOS) {
        // Safari version comes as     "... Version/MAJOR.MINOR ..."
        // Chrome version comes as     "... Chrome/MAJOR.MINOR ..."
        // FirefoxIOS version comes as "... FxiOS/MAJOR.MINOR ..."
        // ChromeIOS version comes as  "... CriOS/MAJOR.MINOR ..."
        let searchWord;
        if (isFirefoxIOS) {
            searchWord = 'FxiOS';
        } else if (isChromeIOS) {
            searchWord = 'CriOS';
        } else if (isEdgeIOS) {
            searchWord = 'EdgiOS';
        } else if (isSafari) {
            searchWord = 'Version';
        } else {
            searchWord = 'Chrome';
        }
        const words = userAgent.split(' ');
        for (const word of words) {
            if (word.includes(searchWord)) {
                uaFields.webViewVersion = word.split('/')[1];
            }
        }
    } else if (isFirefox || isEdge) {
        const reverseUA = userAgent.split('').reverse().join('');
        const reverseVersion = reverseUA.split('/')[0];
        uaFields.webViewVersion = reverseVersion.split('').reverse().join('');
    }

    return uaFields;
}

export const sanatizeAddress = (address: Address | undefined): Address | undefined => {
    if (!address) return

    return {
        ...address,
        postalCode: address.postalCode ?? undefined,
        street: address.street ?? undefined,
        streetNumber: address.streetNumber ?? undefined,
    }
}

export const sanatizeOrder = (order: Order): {
    customer: string;
    pictures: string[]
} & Omit<Order, 'customer' | 'pictures'> => {
    const userStore = useUserStore()

    return {
        ...order,
        address: sanatizeAddress(order.address),
        customer: userStore.currentUser?.id ?? '',
        pictures: order.pictures.map(picture => {
            if (typeof picture === 'string') return picture
            return picture.id
        }),
        plannedEndAt: convertToISO(order.plannedEndAt) ?? undefined,
        plannedStartAt: convertToISO(order.plannedStartAt) ?? undefined,
        sectors: order.sectors.map(sector => {
            if (typeof sector === 'string') return sector
            return sector.id
        })
    }
}

export const cleanedObject = (obj: unknown): any => {
    const cleanedObj = JSON.parse(JSON.stringify(obj))

    for (const key in obj) {
        if (obj[key] === undefined || obj[key] === '' || obj[key] === null) delete cleanedObj[key]
    }

    return cleanedObj
}

export const sanatizeBusiness = (business: Business): Business => {
    return {
        ...business,
        businessRegistration: business.businessRegistration?.id,
        portfolioPictures: business.portfolioPictures.map(picture => {
            if (typeof picture === 'string') return picture
            return picture?.id
        }),
        profilePicture: business.profilePicture?.id,
        sectors: business.sectors.map(sector => {
            if (typeof sector === 'string') return sector
            return sector?.id
        }),
    }
}
