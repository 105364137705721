<template>
    <div
        class="d-flex gap-3 border-radius-default bg-light overflow-hidden pr-3 cursor-pointer"
        style="max-height: 90px;"
        @click="showOrder(order)"
    >
        <image-placeholder :src="order.pictures[0]?.url" />

        <div
            class="flex-grow-100 d-grid card-contractor-grid py-3"
            :class="{ 'limit-info-width': !basicOnly }"
        >
            <div
                class="fw-600"
                style="grid-column: span 3;"
            >
                {{ order.name }}
            </div>

            <order-info-sector :sectors="order.sectors" />

            <order-info-distance :distance-in-meter="order.additionalInformation?.distanceInMeter" />

            <order-info-timeframe :order="order" />
        </div>

        <template v-if="!basicOnly">
            <div
                class="items-center d-flex flex-grow-1 justify-end"
                style="flex-basis: 100px;"
            >
                <button-base
                    v-if="order.additionalInformation.requestStatus === REQUEST_STATUS.OPEN"
                    :disable="!userStore.currentUser?.isEmailConfirmed"
                    @click.stop="$emit('request', order)"
                >
                    {{ $t('button.request') }}
                </button-base>

                <button-base
                    v-else-if="order.additionalInformation.requestStatus === REQUEST_STATUS.REQUESTED_BY_CONTRACTOR"
                    text-color="primary"
                    outline
                    class="label-state"
                >
                    {{ $t('button.requested') }}
                </button-base>

                <div v-else>
                    {{ $t(`label.requestStatus.${order.additionalInformation.requestStatus}`) }}
                </div>
            </div>

            <div
                v-if="order.additionalInformation.unreadRequests"
                class="d-flex items-center"
            >
                <div class="circle bg-negative">
                    {{ order.additionalInformation.unreadRequests }}
                </div>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ButtonBase } from "@/components";
import ImagePlaceholder from "@/components/snippets/ImagePlaceholder.vue";
import OrderInfoDistance from "@/components/snippets/OrderInfoDistance.vue";
import OrderInfoSector from "@/components/snippets/OrderInfoSector.vue";
import OrderInfoTimeframe from "@/components/snippets/OrderInfoTimeframe.vue";

import { useUserStore } from "@/stores/user.ts";

import { showOrder } from "@/functions";

import { REQUEST_STATUS } from "@/types";
import { APIOrder } from "@/types/object/class/db.ts";

defineProps<{ basicOnly?: boolean; order: APIOrder; }>()
defineEmits<{ (e: 'request', order: APIOrder): void; }>()

const userStore = useUserStore()
</script>

<style scoped>
.card-contractor-grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 0.5em;
}

.limit-info-width {
    max-width: calc(100% - 220px - 3rem);
}
</style>
