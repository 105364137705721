import { ORDER_STATUS, REQUEST_STATUS, USER_TYPE } from "@/types";

const label = {
    aboutBusiness: 'Über {business}',
    addCompanyPicture: 'Bild deines Betriebs hinzufügen | Bilder deines Betriebs hinzufügen',
    addProfilePicture: 'Profilbild hinzufügen',
    address: 'Addresse',
    benefits: {
        advertisement: 'Bewerbe deinen Betrieb auf EGOON',
        contact: 'Tritt in wenigen Klicks mit interessanten Auftraggebern in deiner Nähe in Kontakt',
        freeContacts: '{amount} freie Kontaktanfragen',
        notifications: 'Erhalte Benachrichtigungen zu neuen Aufträgen in deiner Nähe'
    },
    businessCert: 'Gewerbeanmeldung',
    businessRequests: {
        redeemedRequests: 'Verbrauchte Anfragen',
        totalRequests: 'Anfragen Total',
        unclaimedRequests: 'Offene Anfragen',
    },
    businessRequestSuccessHint: 'Du hast den Betrieb {name} erfolgreich angefragt!{linebreak}' +
        'Wir Benachrichtigen dich per E-Mail sobald {name} sich bei dir zurückmeldet.{linebreak}' +
        'Nach einer angenommenen Anfrage schalten wir Adresse und Kontaktdaten des Betriebs für dich frei.',
    buttonUploadImages: 'Bilder deines Auftrags',
    completeInfo: 'Vervollständige deine Angaben!',
    confirm: 'bestätigen',
    contactPerson: 'Kontaktperson',
    contractorCloseBy: 'Handwerker in deiner Nähe',
    corporateCert: 'Gewerbeanmeldung',
    createOrder: 'Auftrag erstellen',
    customer: 'den Auftraggeber',
    distance: 'Distanz',
    editOrder: 'Auftrag bearbeiten',
    emailVerified: 'Deine Email wurde erfolgreich verifiziert',
    emailVerifyError: 'Ein Problem ist bei der Verifizierung aufgetreten. Versuche es später erneut oder sende dir eine neue Email',
    establishedContacts: 'Enstandene Kontakte',
    expectation: 'Erwartung',
    filter: 'Filter',
    finishOrder: 'Auftrag abschliessen & Firma bewerten',
    forOrder: 'Für Auftrag',
    gallery: 'Galerie',
    hasAccount: 'Schon einen Account erstellt',
    hintCompanyDescription: 'Bilder und eine Beschreibung können potentiellen Auftraggebern helfen, sich für ' +
        'deinen Betrieb zu entscheiden und deine Chancen für neue Aufträge erhöhen.{newLine}' +
        'Bilder und Beschreibungstext können auch zu einem späteren Zeitpunkt hinzugefügt werden.',
    images: 'Bilder',
    issue: 'Problem',
    loginNow: 'Jetzt anmelden',
    matchedContacts: 'Akzeptierte Anfragen',
    missingInformation: 'Fehlende Information',
    noAccount: 'Noch keinen Account',
    noActiveOrders: 'Keine aktiven Aufträge vorhanden. Erstelle und/oder aktiviere deine Aufträge.',
    noBusinessRegistration: 'Keine Gewerbeanmeldung vorhanden',
    noCert: 'Keine Gewerbeanmeldung',
    noPictures: 'Kein Bild vorhanden | Keine Bilder vorhanden',
    noRatings: 'Keine Bewertungen',
    noRequests: 'Keine Anfragen',
    noSectorsProvided: 'Keine Aufgabenbereich angegeben',
    [ORDER_STATUS.ACTIVE]: 'Aktiv',
    [ORDER_STATUS.DRAFT]: 'Entwurf',
    [ORDER_STATUS.INACTIVE]: 'Inaktiv',
    orderClosed: 'Auftrag wurde abgeschlossen',
    orderDescription: 'Auftragsbeschreibung',
    orderRequestSuccessHint: 'Du hast den Auftrag {ordername} erfolgreich angefragt!{linebreak}' +
        'Wir benachrichtigen dich per E-Mail sobald sich der Auftraggeber zurückmeldet.{linebreak}' +
        'Nach einer angenommenen Anfrage schalten wir Adresse und Kontaktdaten des Auftraggebers für dich frei.',
    orderRequestWarning: '{warning} Bei Anfrage eines Auftrags wird dir Guthaben deines Abonnements abgezogen.{linebreak}' +
        'Bitte frage nur Aufträge an, die dir wirklich zusagen.{linebreak}' +
        'Nach einer angenommenen Anfrage schalten wir Adresse und Kontaktdaten des Auftraggebers für dich frei.',
    ordersAndRequests: 'Anfragen und Aufträge',
    ordersCloseBy: 'Aufträge in deiner Nähe',
    place: 'Ort',
    portfolio: 'Portfolio',
    profileData: 'Deine Benutzerdaten',
    profilePicture: 'Profilbild',
    pwConfirm: '@:input.password @:label.confirm',
    rating: 'Kunde bewertet am {date}',
    ratings: 'Bewertungen',
    redeemedRequests: 'Vom Auftraggeber angefragte Betriebe',
    registerNow: 'Jetzt registrieren',
    request: '{count} Anfrage | {count} Anfragen',
    requestBusinessFor: 'Wähle deinen Auftrag für den du "{business}" anfragen möchtest',
    requestByCustomer: 'Anfragen von Auftraggebern',
    requestCredit: 'Dein aktuelles Guthaben: {credit}',
    requestCreditOrder: 'Übrige Anfragen für deinen Auftrag: {credit}',
    requestFromContractor: 'Anfragen von Betrieb',
    requestMsg: 'Anfragenachricht von Betrieb',
    requestPresent: 'Du hast eine neue Anfrage',
    requestsFromContractors: 'Bisherige Anfragen für den Auftrag',
    requestStatus: {
        'ACCEPTED': 'Auftrag akzeptiert',
        'DECLINED': 'Auftrag abgelehnt',
        [REQUEST_STATUS.CANCELED]: 'Auftrag abgebrochen',
        [REQUEST_STATUS.REQUESTED_BY_CUSTOMER]: 'Von Kunde angefragt',
        [REQUEST_STATUS.REQUESTED_BY_CONTRACTOR]: 'Von Auftragnehmer angefragt',
    },
    resetPassword: 'Passwort zurücksetzen',
    review: 'Bewertung',
    reviewRequestBusiness: 'Die Firma "{business}" hat den Auftrag als erledigt gemeldet. Gib eine kurze Bewertung ab und schliesse den Auftrag.',
    route: {
        additionalRequests: 'Anfragen kaufen',
        BusinessManagement: 'Firmenverwaltung',
        DashboardAdmin: 'Dashboard',
        EditBusiness: 'Betrieb bearbeiten',
        EditProfile: 'Profil bearbeiten',
        Home: 'Dashboard',
        ManageSubscription: 'Abonnement verwalten',
        OrderManagement: 'Auftragverwaltung',
        OrdersAndRequests: 'Anfragen und Aufträge',
        OrdersAndRequestsContractor: 'Anfragen und Aufträge',
        RequestsOverview: 'Anfragenübersicht',
        SuggestionManagement: 'Kategorieverwaltung',
        Support: 'Support',
        UserManagement: 'Userverwaltung'
    },
    selectPlaceholder: 'Bitte auswählen',
    selectTitle: 'Auswählen',
    sendMsgTo: 'Sende eine Nachricht an {reciever}',
    services: 'Dienstleistungen',
    steps: 'Schritte',
    submitCertCondition: 'Die Gewerbeanmeldung kann auch zu einem späteren Zeitpunkt nachgereicht werden.{newline}' +
        'Ohne die vorliegende Gewerbeanmeldung ist es nicht möglich, Anfragen an Aufraggeber zu senden oder zu erhalten.',
    supportForm: 'Support',
    uploadLater: 'Bilder können auch zu einem späteren Zeitpunkt hinzugefügt werden.',
    [USER_TYPE.ADMIN]: 'Admin',
    [USER_TYPE.CONTRACTOR]: 'Betrieb',
    [USER_TYPE.CUSTOMER]: 'Auftraggeber',
    verificationMailSent: 'Verifizierungsemail versendet',
    warning: 'Achtung!',
    yourBusiness: 'Dein Betrieb',
    yourOrder: 'Deine Aufträge',
    yourOverview: 'Deine Übersicht',
    yourProfile: 'Dein Profil',
    yourRequestedOrders: 'Deine angefragten Aufträge',
    yourRequests: 'Deine Anfragen',
    forgotPw: 'Passwort vergessen'
}

export default label;
