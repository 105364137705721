import {
    ABO_TYPE,
    ABO_TYPE_TYPE,
    ISODate,
    ORDER_STATUS,
    ORDER_STATUS_TYPE,
    REQUEST_STATUS_TYPE,
    STATUS,
    STATUS_TYPE,
    SUGGESTION_TYPE,
    SUGGESTION_TYPE_TYPE,
    USER_TYPE,
    USER_TYPE_TYPE
} from "@/types";

abstract class DBObject {
    public createdAt?: ISODate;
    public deletedAt?: ISODate;
    public id?: string;
    public isDeleted?: boolean
    public updatedAt?: ISODate;

    protected constructor(obj?: Partial<DBObject>) {
        this.id = obj?.id
        this.createdAt = obj?.createdAt
        this.updatedAt = obj?.updatedAt
        this.deletedAt = obj?.deletedAt
        this.isDeleted = obj?.isDeleted
    }

    hasId(): boolean {
        return this.id !== undefined
    }
}

export abstract class User extends DBObject {
    public email: string;
    public firstName: string;
    public isEmailConfirmed: boolean;
    public lastName: string;
    public password?: string;
    public phone: string;

    protected constructor(user?: Partial<User>) {
        super(user)

        this.phone = user?.phone ?? ''
        this.email = user?.email ?? ''
        this.password = user?.password
        this.firstName = user?.firstName ?? ''
        this.lastName = user?.lastName ?? ''
        this.isEmailConfirmed = user?.isEmailConfirmed ?? false
    }

    abstract userRole: USER_TYPE_TYPE;
}

export class Customer extends User {
    public userRole: typeof USER_TYPE.CUSTOMER

    constructor(user?: Partial<Omit<Customer, 'userRole'>>) {
        super(user);

        this.userRole = USER_TYPE.CUSTOMER
    }
}

export class Contractor extends User {
    public userRole: typeof USER_TYPE.CONTRACTOR

    constructor(user?: Partial<Omit<Contractor, 'userRole'>>) {
        super(user);

        this.userRole = USER_TYPE.CONTRACTOR
    }
}

type ForeignID = string

export interface FileAccess {
    file: ForeignID;
    fileName: string;
    remainingUses: number;
    url: string;
    validUntil: ISODate;
}

export class Abo extends DBObject {
    public aboType: ABO_TYPE_TYPE = ABO_TYPE.BASIS;
    public price: number;
    public totalRequests: number;

    constructor(abo?: Partial<Abo>) {
        super(abo)

        this.aboType = abo?.aboType ?? ABO_TYPE.STANDARD
        this.price = abo?.price ?? 0
        this.totalRequests = abo?.totalRequests ?? 0
    }
}

export class Address {
    public city: string;
    public location: {
        coordinates: number[];
        type: string;
    }
    public postalCode: string;
    public street: string;
    public streetNumber: string;

    constructor(address?: Partial<Address>) {
        this.street = address?.street ?? ''
        this.streetNumber = address?.streetNumber ?? ''
        this.postalCode = address?.postalCode ?? ''
        this.city = address?.city ?? ''
        this.location = address?.location ?? {
            coordinates: [],
            type: 'Point'
        }
    }
}

export class Payment extends DBObject {
    public customerId: string;
    public subscriptionCycles: any[];
    public subscriptionId: string;
    public subscriptionName: string;
    public subscriptionStatus: string;

    constructor(payment?: Partial<Payment>) {
        super(payment);

        this.customerId = payment?.customerId ?? ''
        this.subscriptionId = payment?.subscriptionId ?? ''
        this.subscriptionName = payment?.subscriptionName ?? ''
        this.subscriptionStatus = payment?.subscriptionStatus ?? ''
        this.subscriptionCycles = payment?.subscriptionCycles ?? []
    }
}

export class SectorSuggestion {
    public suggestion: ForeignID;
    public suggestionType: SUGGESTION_TYPE_TYPE

    constructor(sectorSug?: Partial<SectorSuggestion>) {
        this.suggestion = sectorSug?.suggestion
        this.suggestionType = sectorSug?.suggestionType ?? SUGGESTION_TYPE.SECTOR
    }
}

export class Suggestion extends DBObject {
    public suggestion: string;
    public suggestionType: SUGGESTION_TYPE_TYPE

    constructor(suggestion?: Partial<Suggestion>) {
        super(suggestion);

        this.suggestionType = suggestion?.suggestionType ?? SUGGESTION_TYPE.SECTOR
        this.suggestion = suggestion?.suggestion ?? ''
    }
}

export class Review {
    public description: string;
    public rating: number;
    public referencedOrder: string;

    constructor(review?: Partial<Review>) {
        this.description = review?.description
        this.rating = review?.rating
        this.referencedOrder = review?.referencedOrder
    }
}

export interface APIReview extends Review {
    createdBy: {
        firstName: string;
        id: string;
        lastName: string;
        name: string;
    };
    publishedAt: ISODate;
}

export class Request extends DBObject {
    public business: Business | ForeignID;
    public confirmedAt?: ISODate;
    public message: string;
    public order: ForeignID | Order;
    public requestedAt: ISODate;
    public requestedFrom: USER_TYPE_TYPE;
    public status: STATUS_TYPE;

    constructor(request: Partial<Request>) {
        super(request);

        this.message = request.message ?? ''
        this.status = request.status ?? STATUS.PENDING
        this.order = request.order ?? ''
        this.business = request.business ?? ''
        this.requestedFrom = request.requestedFrom ?? USER_TYPE.CUSTOMER
        this.requestedAt = request.requestedAt
        this.confirmedAt = request.confirmedAt
    }
}

export class Business extends DBObject {
    public abo: Abo;
    public aboCancelledAt?: ISODate;
    public aboExpiresAt?: ISODate;
    public aboStartedAt?: ISODate;
    public address: Address;
    public businessRegistration?: FileAccess;
    public contractor: Contractor;
    public corporateForm: string;
    public description: string;
    public isAboCancelled?: boolean = false;
    public isVerified: boolean;
    public name: string;
    public payment?: Payment;
    public portfolioPictures: FileAccess[];
    public profilePicture?: FileAccess;
    public redeemedRequests?: number;
    public requests?: (ForeignID | Request)[];
    public reviews: Review[];
    public searchRange: number = 150;
    public sectors: (ForeignID | SectorSuggestion)[];
    public totalRating?: number;
    public totalRequests?: number;
    public unclaimedRequests?: number;

    constructor(business?: Partial<Business>) {
        super(business)

        this.contractor = business?.contractor ?? new Contractor()
        this.name = business?.name ?? ''
        this.address = business?.address ?? new Address()
        this.sectors = business?.sectors ?? []
        this.description = business?.description ?? ''
        this.portfolioPictures = business?.portfolioPictures ?? []
        this.profilePicture = business?.profilePicture
        this.corporateForm = business?.corporateForm ?? ''
        this.businessRegistration = business?.businessRegistration
        this.abo = business?.abo ?? new Abo()

        this.searchRange = business?.searchRange ?? 150
        this.totalRating = business?.totalRating
        this.reviews = business?.reviews ?? []
        this.totalRequests = business?.totalRequests
        this.redeemedRequests = business?.redeemedRequests
        this.unclaimedRequests = business?.unclaimedRequests
        this.aboStartedAt = business?.aboStartedAt
        this.aboExpiresAt = business?.aboExpiresAt
        this.aboCancelledAt = business?.aboCancelledAt
        this.isAboCancelled = business?.isAboCancelled
        this.requests = business?.requests ?? []
        this.payment = business?.payment
        this.isVerified = business?.isVerified ?? false
    }
}

export interface RequestTracker {
    redeemedRequests: number;
    totalRequests: number;
    unclaimedRequests: number;
}

export interface APIBusiness extends Business, RequestTracker {
    additionalInformation: {
        distanceInMeter?: number;
        matchingOrderID: string;
        requestStatus?: REQUEST_STATUS_TYPE;
        unreadRequests?: number;
    }
    averageRating: number;
    favouriteOrders: Order[];
    id: string;
    isActive: boolean;
    isVerified: boolean;
    numberOfRatings: number;
    reviews: APIReview[]
    singleOrderRequests: RequestTracker;
    subscriptionRequests: RequestTracker;
}

export class Order extends DBObject {
    public address: Address
    public customer: Customer
    public description: string
    public establishedContacts: number
    public name: string;
    public pictures: FileAccess[]
    public plannedEndAt: ISODate;
    public plannedStartAt: ISODate;
    public publishedAt: ISODate;
    public redeemedRequests: number
    public requestsFromContractors: number
    public searchRange: number = 150
    public sectors: SectorSuggestion[]
    public status: ORDER_STATUS_TYPE;
    public totalRequests: number
    public unclaimedRequests: number

    constructor(order?: Partial<Order>) {
        super(order);

        this.customer = order?.customer ?? new Customer()
        this.sectors = order?.sectors ?? []
        this.name = order?.name ?? ''
        this.description = order?.description
        this.pictures = order?.pictures ?? []
        this.address = order?.address ?? new Address()
        this.searchRange = order?.searchRange ?? 150
        this.plannedStartAt = order?.plannedStartAt ?? ''
        this.plannedEndAt = order?.plannedEndAt ?? ''
        this.status = order?.status ?? ORDER_STATUS.DRAFT
        this.publishedAt = order?.publishedAt ?? ''
        this.totalRequests = order?.totalRequests ?? 0
        this.redeemedRequests = order?.redeemedRequests ?? 0
        this.unclaimedREquests = order?.unclaimedRequests ?? 0
        this.requestsFromContractors = order?.requestsFromContractors ?? 0
        this.establishedContacts = order?.establishedContacts ?? 0
    }
}

export interface APIOrder extends Order {
    additionalInformation: {
        isFavourite: boolean;
        requestStatus: REQUEST_STATUS_TYPE;
        unreadRequests: number;
    };
    id: string;
}

export interface APIRequest extends DBObject {
    business: APIBusiness | string;
    isUnreadFromContractor: boolean;
    isUnreadFromCustomer: boolean;
    message: string;
    order: APIOrder | string;
    previousStatus: REQUEST_STATUS_TYPE;
    readAt: ISODate;
    repliedAt: ISODate;
    requestedAt: ISODate;
    requestedBy: USER_TYPE.CONTRACTOR | USER_TYPE.CUSTOMER;
    status: REQUEST_STATUS_TYPE;
}
