<template>
    <q-layout view="hHh lpR fFf">
        <custom-sidebar>
            <q-img
                src="/Egoon_logo.svg"
                class="mt-2 mb-5"
                ratio="1"
                fit="contain"
                style="height: var(--logo-size); width: calc(2 * var(--logo-size));"
            />

            <q-list>
                <q-item-label
                    header
                    class="text-textm"
                >
                    {{ $t('label.yourOverview') }}
                </q-item-label>

                <q-item
                    v-for="routeEl of adminRoutes"
                    :key="routeEl.routeName"
                    v-ripple
                    clickable
                    :active="route.name === routeEl.routeName || routeEl.activeRoutes?.includes(route.name)"
                    active-class="fw-b"
                    class="sidebar-option select-none"
                    @click="router.push({ name: routeEl.routeName })"
                >
                    <q-item-section side>
                        <q-icon
                            :name="routeEl.icon"
                            :color="route.name === routeEl.routeName || routeEl.activeRoutes?.includes(route.name) ? 'primary' : 'text'"
                        />
                    </q-item-section>

                    <q-item-section>
                        {{ $t(`label.route.${routeEl.routeName}`) }}
                    </q-item-section>
                </q-item>

                <q-item class="justify-center mt-5">
                    <button-base
                        outline
                        text-color="primary"
                        icon="logout"
                        :label="$t('button.logout')"
                        @click="logout()"
                    />
                </q-item>
            </q-list>
        </custom-sidebar>

        <q-page-container>
            <q-page
                padding
                style="padding-top: calc(var(--logo-size, 0px) + 3.75em); min-width: 860px;"
            >
                <slot />
            </q-page>
        </q-page-container>
    </q-layout>
</template>

<script setup lang="ts">
import { QIcon, QImg, QItem, QItemLabel, QItemSection, QLayout, QList, QPage, QPageContainer } from "quasar";

import { ButtonBase, CustomSidebar } from "@/components";

import { logout } from "@/functions";

import router, { route, ROUTE_NAME_ADMIN, ROUTE_NAME_ADMIN_TYPE } from "@/router";

type RouteObject = {
    activeRoutes?: ROUTE_NAME_ADMIN_TYPE[];
    // clickFn?: FunctionType
    icon: string;
    routeName: ROUTE_NAME_ADMIN_TYPE | string;
}

const adminRoutes: RouteObject[] = [
    {
        icon: 'home',
        routeName: ROUTE_NAME_ADMIN.DASHBOARD_ADMIN,
    },
    {
        icon: 'people',
        routeName: ROUTE_NAME_ADMIN.USER_MANAGEMENT,
    },
    {
        icon: 'business',
        routeName: ROUTE_NAME_ADMIN.BUSINESS_MANAGEMENT,
    },
    {
        icon: 'request_page',
        routeName: ROUTE_NAME_ADMIN.ORDER_MANAGEMENT,
    },
]
</script>

<style scoped>

</style>
