import axios, { AxiosError } from "axios";
import { LocalStorage } from "quasar";

import router, { ROUTE_NAME } from "@/router";

import type { AuthCredentials, AuthResult } from "@/types";

export const TOKEN_STORAGE = 'authKey'
export const ADMIN_STORAGE = 'adminKey'
let authToken = ''
let isAdmin: boolean | undefined

export const login = async (credentials: AuthCredentials): Promise<AuthResult> => {
    if (!credentials.username || !credentials.password) throw Error('Missing Credentials')
    const response = await axios.post('auth/login', credentials).catch((err: AxiosError) => {
        if (err.response?.data?.error === 'INVALID_LOGIN') return 'INVALID_LOGIN'
    })

    if (response === 'INVALID_LOGIN') {
        throw Error('INVALID_LOGIN')
    }

    if (response?.data.token) {
        setToken(response.data.token)
        // setAdminPermission(response.data.isAdmin)
    }
    return response?.data
}

export const logout = (noRouting = false): void => {
    authToken = ''
    LocalStorage.remove(TOKEN_STORAGE)
    LocalStorage.remove(ADMIN_STORAGE)
    if (!noRouting) router.push({ name: ROUTE_NAME.LOGIN })
}

export const setToken = (token: string): void => {
    authToken = token
    return LocalStorage.set(TOKEN_STORAGE, token)
}

export const setAdminPermission = (permission: boolean): void => {
    LocalStorage.set(ADMIN_STORAGE, permission)
}

export const getAdminPermission = (): boolean => {
    if (isAdmin !== undefined) return isAdmin

    const storage = LocalStorage.getItem(ADMIN_STORAGE)

    if (!storage) return false

    return storage === 'true'
}

export const getToken = (): string => {
    if (authToken) return authToken

    const storage = LocalStorage.getItem(TOKEN_STORAGE)

    if (!storage || typeof storage !== 'string') return ''

    return storage
}
